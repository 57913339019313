import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import betAmountOptions from '../lib/betAmountOptions'
import formatGold from '../lib/formatGold'
import formatJewel from '../lib/formatJewel'
import balanceSelectors from '../modules/balance/balanceSelectors'
import completedGamesSelectors from '../modules/completedGames/completedGamesSelectors'
import liveGamesActions from '../modules/liveGames/liveGamesActions'
import liveGamesSelectors from '../modules/liveGames/liveGamesSelectors'
import styles from '../styles/InfoBar.module.scss'

export default function InfoBar(props: { goldPriceInUsd: number }) {
  const dispatch = useDispatch()
  let [betAmount, setBetAmount] = useState('')

  const betAmountsAvailableAsOptions: any = betAmountOptions(
    props.goldPriceInUsd,
  ).map((betAmount: any) => {
    return { value: betAmount, label: formatGold(betAmount) }
  })

  const goldBalance = useSelector(balanceSelectors.selectGoldBalance)
  const jewelBalance = useSelector(balanceSelectors.selectJewelBalance)
  const balanceState = useSelector(balanceSelectors.selectState)

  const gamesPlayed = useSelector(completedGamesSelectors.selectTotal)
  const gamesPlayedState = useSelector(completedGamesSelectors.selectState)
  const activeGames = useSelector(liveGamesSelectors.selectActiveCount)
  const activeGamesState = useSelector(liveGamesSelectors.selectState)

  useEffect(() => {
    dispatch(
      liveGamesActions.doChangeFilter({
        betAmount,
      }),
    )
  }, [dispatch, betAmount])

  return (
    <aside className={styles.infoBar}>
      <div className={styles.items}>
        {(gamesPlayedState === 'idle' || gamesPlayedState === 'empty') && (
          <div className={styles.item}>
            <Image
              alt="Games Played"
              src={'/info-bar-lobby/1.svg'}
              width={24}
              height={16}
            />
            <div className={styles.itemText}>
              <Link href="/ranking">
                <div style={{ cursor: 'pointer' }}>
                  <b>{gamesPlayed}</b> Games Played
                </div>
              </Link>
            </div>
          </div>
        )}

        {balanceState === 'idle' && (
          <div className={styles.item}>
            <Image
              alt="Bank"
              src={'/info-bar-lobby/2.svg'}
              width={24}
              height={24}
            />
            <div className={styles.itemText}>
              <span style={{ display: 'flex' }}>
                <b>{formatGold(goldBalance)} G</b>
                <b style={{ marginLeft: 12 }}>{formatJewel(jewelBalance)} J</b>
              </span>
              Bank
            </div>
          </div>
        )}
        {(activeGamesState === 'idle' || activeGamesState === 'empty') && (
          <div className={styles.item}>
            <Image
              alt="Active Games"
              src={'/info-bar-lobby/2.svg'}
              width={24}
              height={24}
            />
            <div className={styles.itemText}>
              <b>{activeGames}</b> Active Games
            </div>
          </div>
        )}
      </div>
      <div className={styles.manage}>
        <div>&#160;</div>
        <Select
          className="custom-select h40"
          classNamePrefix="custom-select"
          options={betAmountsAvailableAsOptions}
          value={betAmount}
          onChange={(e: any) => setBetAmount(e)}
          isClearable={true}
          placeholder={'Bet amount...'}
        />
      </div>
    </aside>
  )
}
