import BigNumber from 'bignumber.js'

export default function formatGoldRaw(arg) {
  if (!arg) {
    return 0
  }

  const balance = new BigNumber(arg).dividedBy(
    Number(10 ** Number(process.env.NEXT_PUBLIC_GOLD_DECIMALS)),
  )
  return balance.toString()
}
