import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Web3 from 'web3'
import BattleContractAbi from '../../contracts/BattleContractAbi'
import HeroContractAbi from '../../contracts/HeroContractAbi'
import classNames from '../../lib/classNames'
import formatGold from '../../lib/formatGold'
import Messages from '../../lib/messages'
import authActions from '../../modules/auth/authActions'
import balanceActions from '../../modules/balance/balanceActions'
import balanceSelectors from '../../modules/balance/balanceSelectors'
import BalanceService from '../../modules/balance/balanceService'
import { ConfigStore } from '../../modules/config/configStore'
import { Web3Errors } from '../../modules/error/Web3Errors'
import liveGamesActions from '../../modules/liveGames/liveGamesActions'
import { LiveGamesHeroStore } from '../../modules/liveGames/liveGamesHeroStore'
import liveGamesSelectors from '../../modules/liveGames/liveGamesSelectors'
import persistentToastActions from '../../modules/persistentToast/persistentToastActions'
import styles from '../../styles/Card.module.scss'
import HeroCard from '../heroCard/HeroCard'
import { HeroSelectModal } from '../heroes/heroSelectModal'
import { JoinConfirmModal } from './joinConfirmModal'

export function JoinCard(props) {
  const { liveDuel } = props
  const { account } = useWeb3React()
  const dispatch = useDispatch()

  const [heroSelectionOpen, setHeroSelectionOpen] = useState(false)
  const heroesIdsInUse = useSelector(liveGamesSelectors.selectHeroesIdsInUse)

  const goldBalance = useSelector(balanceSelectors.selectGoldBalance)
  const jewelBalance = useSelector(balanceSelectors.selectJewelBalance)

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [jewelAmountNeeded, setJewelAmountNeeded] = useState(null)
  const [goldTaxRate, setGoldTaxRate] = useState(0)

  const [state, setState] = useState('idle')
  const [hero, setHero] = useState(null)

  useEffect(() => {
    BalanceService.fetchGoldTaxRate(0)
      .then((goldTaxRate) => setGoldTaxRate(goldTaxRate))
      .catch((error) => {
        Web3Errors.handle(error, true)
        console.error(error)
      })
  }, [])

  useEffect(() => {
    if (hero) {
      doOpenConfirm()
    }
  }, [hero?.id])

  useEffect(() => {
    if (hero && heroesIdsInUse.includes(hero.id)) {
      setHero(null)
    }
  }, [heroesIdsInUse, hero?.id])

  function doOpenSelectHero() {
    if (!account) {
      dispatch(authActions.doOpenModal())
      return
    }

    if (
      ethers.BigNumber.from(goldBalance).lt(ethers.BigNumber.from(liveDuel.bet))
    ) {
      Messages.error(`Not enough GOLD to join this duel.`)
      setState('idle')
      return
    }

    setHeroSelectionOpen(true)
  }

  async function doOpenConfirm() {
    try {
      setState('loading')
      setJewelAmountNeeded(
        (await BalanceService.jewelAmountForDuel(hero.level)).toString(),
      )
      setConfirmOpen(true)
    } catch (error) {
      Web3Errors.handle(error)
      setState('idle')
    }
  }

  function onConfirmClose() {
    setState('idle')
    setConfirmOpen(false)
  }

  const callContractToJoinGame = async () => {
    setConfirmOpen(false)

    if (!account) {
      dispatch(authActions.doOpenModal())
      return
    }

    try {
      setState('loading')

      if (
        ethers.BigNumber.from(goldBalance).lt(
          ethers.BigNumber.from(liveDuel.bet),
        )
      ) {
        Messages.error(`Not enough GOLD to join this duel.`)
        setState('idle')
        return
      }

      if (
        !(await BalanceService.isJewelAmountEnoughForDuel(
          jewelBalance,
          hero.level,
        ))
      ) {
        Messages.error(`Not enough JEWEL to join this duel.`)
        setState('idle')
        return
      }

      // @ts-ignore
      const web3Instance = new Web3(window.ethereum)

      const battleContract = new web3Instance.eth.Contract(
        BattleContractAbi,
        ConfigStore.get().BATTLE_CONTRACT_ADDRESS,
      )

      const heroContract = new web3Instance.eth.Contract(
        HeroContractAbi,
        ConfigStore.get().HERO_CONTRACT_ADDRESS,
      )

      const allowance = await heroContract.methods
        .isApprovedForAll(account, battleContract.options.address)
        .call()

      if (!allowance) {
        dispatch(
          persistentToastActions.doAddToast(
            'JoinCard',
            'Waiting for approval to use your DFK Hero...',
          ),
        )

        await heroContract.methods
          .setApprovalForAll(battleContract.options.address, true)
          .send({ from: account })
      }

      dispatch(
        persistentToastActions.doAddToast(
          'JoinCard',
          'Waiting for duel confirmation...',
        ),
      )

      await battleContract.methods
        .join_existing_game(liveDuel.pairId, hero.id, liveDuel.bet)
        .send({ from: account })

      LiveGamesHeroStore.setHeroTo(account, liveDuel.pairId, hero.id)

      dispatch(liveGamesActions.doFetch(account))
      dispatch(balanceActions.doFetch(account, true))

      setState('idle')
    } catch (error) {
      Web3Errors.handle(error)
      setState('idle')
    } finally {
      dispatch(persistentToastActions.doRemoveToast('JoinCard'))
    }
  }

  return (
    <div className={`${styles.card}`}>
      <div className={`${styles.heading} ${styles.headingWaiting}`}>
        <div className={`${styles.player} ${styles.playerEmpty}`}>
          Waiting...
        </div>
        <div className={styles.player}></div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div
            style={{ cursor: 'pointer' }}
            className={classNames(styles.token)}
            onClick={doOpenSelectHero}
          >
            {hero && <HeroCard hero={hero} isAnimated={true} size="medium" />}
          </div>
          <div className={styles.bet}>
            <Image alt="Coin" src={'/gold_coin.png'} width={24} height={24} />
            <b>{formatGold(liveDuel.bet)} G</b>
          </div>
          <div className={`${styles.token}`}></div>
        </div>
        {!hero && (
          <button
            onClick={doOpenSelectHero}
            className={`btn btn--large btn--green ${styles.button}`}
            disabled={state === 'loading'}
          >
            Join Now!
          </button>
        )}
        {hero && (
          <button
            onClick={doOpenConfirm}
            className={`btn btn--large btn--green ${styles.button}`}
            disabled={state === 'loading'}
          >
            Join Now!
          </button>
        )}
      </div>
      <div className={styles.styling}></div>
      <HeroSelectModal
        onHeroSelect={(hero) => {
          setHero({ ...hero })
          setHeroSelectionOpen(false)
        }}
        isOpen={heroSelectionOpen}
        onClose={() => setHeroSelectionOpen(false)}
        duelToJoin={liveDuel}
      />

      <JoinConfirmModal
        isOpen={confirmOpen}
        closeModal={onConfirmClose}
        jewelAmount={jewelAmountNeeded}
        goldAmount={liveDuel.bet}
        onConfirm={callContractToJoinGame}
        goldTaxRate={goldTaxRate}
        type="join"
      />
    </div>
  )
}
