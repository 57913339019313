import { useState } from 'react'
import Modal from 'react-modal'
import { useDisableBodyScroll } from '../../hooks/useDisableBodyScroll'
import Messages from '../../lib/messages'
import { Web3Errors } from '../../modules/error/Web3Errors'
import HeroService from '../../modules/hero/heroService'
import styles from '../../styles/Modals.module.scss'
import { HeroesSelectionList } from './HeroesSelectionList'

interface HeroSelectModalProps {
  onHeroSelect: (hero: any) => void
  onClose: () => void
  isOpen?: boolean
  duelToJoin?: any
}

export function HeroSelectModal(props: HeroSelectModalProps) {
  const [cooldownLoading, setCooldownLoading] = useState(false)
  const { duelToJoin, onClose, isOpen } = props

  useDisableBodyScroll(isOpen)

  async function onHeroSelect(hero) {
    if (!hero) {
      props.onHeroSelect(null)
      return
    }

    try {
      setCooldownLoading(true)
      const cooldownFinishesAt = await HeroService.cooldownFinishesAt(hero.id)

      if (cooldownFinishesAt) {
        Messages.error(
          `${
            hero.name
          } is on cooldown and will be available ${cooldownFinishesAt.fromNow()}`,
        )
        return
      }

      props.onHeroSelect(hero)
    } catch (error) {
      Web3Errors.handle(error)
    } finally {
      setCooldownLoading(false)
    }
  }

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel={'Select Hero Modal'}
        className={`dfkModal ${styles.heroSelectModal}`}
        closeTimeoutMS={200}
      >
        <HeroesSelectionList
          onHeroSelect={onHeroSelect}
          duelToJoin={duelToJoin}
          cooldownLoading={cooldownLoading}
        />
        <button
          className="btn dfkModal__close"
          aria-label="Close modal"
          onClick={onClose}
        />
      </Modal>
    </>
  )
}
