import { padStart } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

export default function CardTimer(props) {
  const deadline = props.deadline

  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const _minutes = moment(deadline).diff(moment(), 'minutes') || 0
    setMinutes(_minutes)
    const _seconds = moment(deadline).diff(moment(), 'seconds') % 60 || 0
    setSeconds(_seconds)

    const interval = setInterval(() => {
      const _minutes = moment(deadline).diff(moment(), 'minutes') || 0
      setMinutes(_minutes)
      const _seconds = moment(deadline).diff(moment(), 'seconds') % 60 || 0
      setSeconds(_seconds)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [deadline])

  if (moment().isAfter(deadline)) {
    return <span>{`00:00`}</span>
  }

  return (
    <span>
      {padStart(String(minutes), 2, '0')}:{padStart(String(seconds), 2, '0')}
    </span>
  )
}
