import { useWeb3React } from '@web3-react/core'
import Image from 'next/image'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import Web3 from 'web3'
import BattleContractAbi from '../../contracts/BattleContractAbi'
import formatGold from '../../lib/formatGold'
import authActions from '../../modules/auth/authActions'
import balanceActions from '../../modules/balance/balanceActions'
import { ConfigStore } from '../../modules/config/configStore'
import { Web3Errors } from '../../modules/error/Web3Errors'
import liveGamesActions from '../../modules/liveGames/liveGamesActions'
import persistentToastActions from '../../modules/persistentToast/persistentToastActions'
import styles from '../../styles/Card.module.scss'
import HeroCard from '../heroCard/HeroCard'

export function WaitingCard(props) {
  const { liveDuel } = props
  const { account } = useWeb3React()
  const dispatch = useDispatch()

  const [state, setState] = useState('idle')

  const callContractToAbandonDuelByFirstPlayer = async () => {
    if (!account) {
      dispatch(authActions.doOpenModal())
      return
    }

    try {
      setState('loading')
      dispatch(
        persistentToastActions.doAddToast(
          'WaitingCard',
          'Waiting for cancel confirmation...',
        ),
      )
      // @ts-ignore
      const web3Instance = new Web3(window.ethereum)

      const battleContract = new web3Instance.eth.Contract(
        BattleContractAbi,
        ConfigStore.get().BATTLE_CONTRACT_ADDRESS,
      )

      await battleContract.methods
        .abandon_game_pre_pairing(liveDuel.pairId)
        .send({ from: account })

      dispatch(liveGamesActions.doFetch(account))
      dispatch(balanceActions.doFetch(account, true))

      setState('idle')
    } catch (error) {
      Web3Errors.handle(error)
      setState('idle')
    } finally {
      dispatch(persistentToastActions.doRemoveToast('WaitingCard'))
    }
  }

  return (
    <div className={`${styles.card} ${styles.cardYellow}`}>
      <div className={`${styles.heading} ${styles.headingWaiting}`}>
        <div className={styles.player}>{liveDuel?.hero?.name}</div>
        <div className={`${styles.player} ${styles.playerEmpty}`}>
          Waiting...
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.token}>
            <HeroCard hero={liveDuel.hero} isAnimated={true} size="medium" />
          </div>
          <div className={styles.bet}>
            <Image alt="Coin" src={'/gold_coin.png'} width={24} height={24} />
            <b>{formatGold(liveDuel.bet)} G</b>
          </div>
          <div className={styles.token}></div>
        </div>
        <button
          onClick={() => {
            callContractToAbandonDuelByFirstPlayer()
          }}
          disabled={state === 'loading'}
          className={`btn btn--large btn--dark ${styles.button}`}
        >
          Waiting for other player... Cancel
        </button>
      </div>
      <div className={styles.styling}></div>
    </div>
  )
}
