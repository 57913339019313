import { useEffect, useState } from 'react'
import { Web3Errors } from '../modules/error/Web3Errors'
import GoldService from '../modules/gold/goldService'

export default function useGoldPriceInUsd() {
  const [goldPriceInUsdState, setGoldPriceInUsdState] = useState('loading')
  const [goldPriceInUsd, setGoldPriceInUsd] = useState(0)

  useEffect(() => {
    doFetchGoldPrice()
  }, [])

  useEffect(() => {
    if (goldPriceInUsdState === 'error') {
      let interval = setInterval(() => {
        doFetchGoldPrice(true)
      }, 10000)

      return () => clearInterval(interval)
    }
  }, [goldPriceInUsdState])

  async function doFetchGoldPrice(skipStateChange = false) {
    try {
      if (!skipStateChange) {
        setGoldPriceInUsdState('loading')
      }
      setGoldPriceInUsd(await GoldService.fetchGoldPriceThreeDecimalsInUsd(0))
      setGoldPriceInUsdState('idle')
    } catch (error) {
      Web3Errors.handle(error, true)
      if (!skipStateChange) {
        setGoldPriceInUsdState('error')
      }
    }
  }

  return { goldPriceInUsd, goldPriceInUsdState }
}
