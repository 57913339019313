import BigNumber from 'bignumber.js'
import React from 'react'
import Modal from 'react-modal'
import formatGold from '../../lib/formatGold'
import formatGoldRaw from '../../lib/formatGoldRaw'
import formatJewel from '../../lib/formatJewel'
import styles from '../../styles/Modals.module.scss'
import numeral from 'numeral'
import { toLower } from 'lodash'

export function JoinConfirmModal(props) {
  const {
    isOpen,
    closeModal,
    onConfirm,
    jewelAmount,
    goldAmount,
    goldTaxRate,
    type,
  } = props

  const goldAmountWithDecimals = new BigNumber(
    formatGoldRaw(goldAmount),
  ).multipliedBy(2)
  const goldWinLessTaxes = new BigNumber(goldAmountWithDecimals).minus(
    new BigNumber(goldAmountWithDecimals).times(goldTaxRate / 10000),
  )

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Join Game"
        className={`dfkModal ${styles.authModal}`}
        closeTimeoutMS={200}
      >
        <div className={styles.modalText}>
          You'll be charged your initial bet of{' '}
          <b>{formatGold(goldAmount)} Gold</b> and{' '}
          <b>{formatJewel(jewelAmount)} Jewel</b> to {toLower(type)} this game.
          On victory, you will receive back{' '}
          <b>{numeral(goldWinLessTaxes.toString()).format('0,0.[000]')}</b>{' '}
          Gold. On loss, you will not receive anything back.
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 16,
          }}
        >
          <button
            style={{ width: 80 }}
            className="btn btn--default btn--green"
            onClick={onConfirm}
          >
            Confirm
          </button>
          <button
            style={{ width: 80 }}
            className="btn btn--default btn--dark"
            onClick={closeModal}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  )
}
