import BigNumber from 'bignumber.js'
import Web3 from 'web3'
import GoldOracleContractAbi from '../../contracts/GoldOracleContractAbi'
import providerUrlForRetry from '../../lib/providerUrlForRetry'
import { sleep } from '../../lib/sleep'
import { ConfigStore } from '../config/configStore'

export default class GoldService {
  static async fetchGoldPriceThreeDecimalsInUsd(retry: number) {
    try {
      retry = Number(retry) || 0
      const web3Instance = new Web3(providerUrlForRetry(0))
      const contract = new web3Instance.eth.Contract(
        GoldOracleContractAbi,
        ConfigStore.get().GOLD_ORACLE_CONTRACT_ADDRESS,
      )

      const priceWith3Decimals = await contract.methods
        .getDollarDenomatedGoldPrice()
        .call()

      return new BigNumber(priceWith3Decimals).toNumber()
    } catch (error) {
      if (retry <= 3) {
        await sleep(retry * 1000)
        return await this.fetchGoldPriceThreeDecimalsInUsd(retry + 1)
      }

      throw error
    }
  }
}
