import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Web3 from 'web3'
import BattleContractAbi from '../../contracts/BattleContractAbi'
import HeroContractAbi from '../../contracts/HeroContractAbi'
import betAmountOptions from '../../lib/betAmountOptions'
import classNames from '../../lib/classNames'
import formatGold from '../../lib/formatGold'
import Messages from '../../lib/messages'
import authActions from '../../modules/auth/authActions'
import balanceActions from '../../modules/balance/balanceActions'
import balanceSelectors from '../../modules/balance/balanceSelectors'
import BalanceService from '../../modules/balance/balanceService'
import { ConfigStore } from '../../modules/config/configStore'
import { Web3Errors } from '../../modules/error/Web3Errors'
import liveGamesActions from '../../modules/liveGames/liveGamesActions'
import liveGamesSelectors from '../../modules/liveGames/liveGamesSelectors'
import LiveGamesService from '../../modules/liveGames/liveGamesService'
import persistentToastActions from '../../modules/persistentToast/persistentToastActions'
import styles from '../../styles/Card.module.scss'
import HeroCard from '../heroCard/HeroCard'
import { HeroSelectModal } from '../heroes/heroSelectModal'
import { JoinConfirmModal } from './joinConfirmModal'

interface CreateSelectedCardProps {
  goldPriceInUsd: number
}

export function CreateSelectedCard(props: CreateSelectedCardProps) {
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  let [hero, setHero] = useState(null)
  const [state, setState] = useState('idle')
  const [goldAmount, setGoldAmount] = useState(props.goldPriceInUsd * 1000)
  const [heroLoading, setHeroLoading] = useState(false)

  const heroesIdsInUse = useSelector(liveGamesSelectors.selectHeroesIdsInUse)
  const [heroSelectionOpen, setHeroSelectionOpen] = useState(false)
  const goldBalance = useSelector(balanceSelectors.selectGoldBalance)
  const jewelBalance = useSelector(balanceSelectors.selectJewelBalance)
  const [goldTaxRate, setGoldTaxRate] = useState(0)
  const [jewelAmountNeeded, setJewelAmountNeeded] = useState(null)
  const [confirmOpen, setConfirmOpen] = useState(false)

  useEffect(() => {
    BalanceService.fetchGoldTaxRate(0)
      .then((goldTaxRate) => setGoldTaxRate(goldTaxRate))
      .catch((error) => {
        Web3Errors.handle(error, true)
        console.error(error)
      })
  }, [])

  async function doOpenConfirm() {
    try {
      setState('loading')
      setJewelAmountNeeded(
        (await BalanceService.jewelAmountForDuel(hero.level)).toString(),
      )
      setConfirmOpen(true)
    } catch (error) {
      Web3Errors.handle(error)
      setState('idle')
    }
  }

  function onConfirmClose() {
    setState('idle')
    setConfirmOpen(false)
  }

  const callContractToCreateDuel = async () => {
    setConfirmOpen(false)

    if (!account) {
      dispatch(authActions.doOpenModal())
      return
    }

    try {
      setState('loading')

      if (
        ethers.BigNumber.from(goldBalance).lt(ethers.BigNumber.from(goldAmount))
      ) {
        Messages.error(`Not enough GOLD to create this duel.`)
        setState('idle')
        return
      }

      if (
        !(await BalanceService.isJewelAmountEnoughForDuel(
          jewelBalance,
          hero.level,
        ))
      ) {
        Messages.error(`Not enough JEWEL to create this duel.`)
        setState('idle')
        return
      }

      // @ts-ignore
      const web3Instance = new Web3(window.ethereum)

      const battleContract = new web3Instance.eth.Contract(
        BattleContractAbi,
        ConfigStore.get().BATTLE_CONTRACT_ADDRESS,
      )

      const heroContract = new web3Instance.eth.Contract(
        HeroContractAbi,
        ConfigStore.get().HERO_CONTRACT_ADDRESS,
      )

      const allowance = await heroContract.methods
        .isApprovedForAll(account, battleContract.options.address)
        .call()

      if (!allowance) {
        dispatch(
          persistentToastActions.doAddToast(
            'CreateSelectedCard',
            'Waiting for approval to use your DFK Hero...',
          ),
        )
        await heroContract.methods
          .setApprovalForAll(battleContract.options.address, true)
          .send({ from: account })
      }

      dispatch(
        persistentToastActions.doAddToast(
          'CreateSelectedCard',
          'Waiting for duel confirmation...',
        ),
      )

      await battleContract.methods
        .create_game(hero.id, goldAmount)
        .send({ from: account })

      await LiveGamesService.storeHeroOnLastGame(account, hero.id)

      setHeroSelectionOpen(false)
      setHero(null)
      dispatch(liveGamesActions.doFetch(account))
      dispatch(balanceActions.doFetch(account, true))

      setState('idle')
    } catch (error) {
      Web3Errors.handle(error)
      setState('idle')
    } finally {
      dispatch(persistentToastActions.doRemoveToast('CreateSelectedCard'))
    }
  }

  function onSelectHeroClick() {
    if (
      ethers.BigNumber.from(goldBalance).lt(ethers.BigNumber.from(goldAmount))
    ) {
      Messages.error(`Not enough GOLD to create this duel.`)
      return
    }
    setHeroSelectionOpen(true)
  }

  useEffect(() => {
    if (hero && heroesIdsInUse.includes(hero.id)) {
      setHero(null)
    }
  }, [heroesIdsInUse, hero?.id])

  return (
    <div className={`${styles.card}`}>
      <div className={`${styles.heading}`}>
        <b>Select your hero and bet</b>
      </div>
      <div className={styles.wrapper}>
        <div className={`${styles.content} ${styles.contentSingle}`}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => setHeroSelectionOpen(true)}
            className={classNames(
              heroLoading
                ? `${styles.timer} ${styles.tokenSelect}`
                : styles.tokenShadow,
              styles.token,
            )}
          >
            <HeroCard hero={hero} isAnimated={true} size="medium" />
          </div>
        </div>
        <div className={styles.manage}>
          {betAmountOptions(props.goldPriceInUsd).map((amount) => {
            return (
              <button
                key={amount}
                onClick={() => {
                  setGoldAmount(amount)
                }}
                className={classNames(
                  amount === goldAmount ? 'btn--blue' : 'btn--dark',
                  'btn btn--large',
                  styles.goldAmountBtn,
                  styles.buttonGoldText,
                )}
              >
                {formatGold(amount)} G
                <Image
                  alt="Coin"
                  src={'/gold_coin.png'}
                  width={18}
                  height={18}
                />
              </button>
            )
          })}
        </div>
        {!hero && (
          <button
            onClick={onSelectHeroClick}
            className={`btn btn--large btn--green ${styles.button}`}
            disabled={state === 'loading'}
          >
            Select Your Hero
          </button>
        )}
        {hero && (
          <button
            onClick={doOpenConfirm}
            className={`btn btn--large btn--green ${styles.button}`}
            disabled={state === 'loading' || heroLoading}
          >
            Create Duel
          </button>
        )}
      </div>
      <div className={styles.styling}></div>
      <HeroSelectModal
        onHeroSelect={(hero) => {
          setHero({ ...hero })
          setHeroSelectionOpen(false)
        }}
        isOpen={heroSelectionOpen}
        onClose={() => setHeroSelectionOpen(false)}
      />

      <JoinConfirmModal
        isOpen={confirmOpen}
        closeModal={onConfirmClose}
        jewelAmount={jewelAmountNeeded}
        goldAmount={goldAmount}
        onConfirm={callContractToCreateDuel}
        goldTaxRate={goldTaxRate}
        type="create"
      />
    </div>
  )
}
