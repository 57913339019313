import { useWeb3React } from '@web3-react/core'
import moment from 'moment'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Web3 from 'web3'
import BattleContractAbi from '../../contracts/BattleContractAbi'
import formatGold from '../../lib/formatGold'
import authActions from '../../modules/auth/authActions'
import balanceActions from '../../modules/balance/balanceActions'
import { ConfigStore } from '../../modules/config/configStore'
import { Web3Errors } from '../../modules/error/Web3Errors'
import liveGamesActions from '../../modules/liveGames/liveGamesActions'
import persistentToastActions from '../../modules/persistentToast/persistentToastActions'
import styles from '../../styles/Card.module.scss'
import HeroCard from '../heroCard/HeroCard'
import CardTimer from './cardTimer'

export function PairedCard(props) {
  const { liveDuel, isClosed } = props
  const { account } = useWeb3React()
  const dispatch = useDispatch()
  const router = useRouter()
  const [isBlockPeriod, setIsBlockPeriod] = useState(true)

  const [state, setState] = useState('idle')

  useEffect(() => {
    const bothConfirmedTime = liveDuel?.bothConfirmedTime

    if (bothConfirmedTime && isBlockPeriod) {
      let pooling = setInterval(() => {
        ;(async () => {
          if (bothConfirmedTime.isBefore(moment().subtract(20, 'seconds'))) {
            setIsBlockPeriod(false)
          }
        })()
      }, 1000)

      return () => {
        if (pooling) {
          clearInterval(pooling)
        }
      }
    }
  }, [liveDuel])

  const callContractToConfirmDuel = async () => {
    if (!account) {
      dispatch(authActions.doOpenModal())
      return
    }

    try {
      setState('loading')
      dispatch(
        persistentToastActions.doAddToast(
          'PairedCard',
          'Waiting for duel confirmation...',
        ),
      )
      // @ts-ignore
      const web3Instance = new Web3(window.ethereum)

      const battleContract = new web3Instance.eth.Contract(
        BattleContractAbi,
        ConfigStore.get().BATTLE_CONTRACT_ADDRESS,
      )

      await battleContract.methods
        .confirm_game_post_pairing(liveDuel.pairId)
        .send({ from: account })

      dispatch(liveGamesActions.doFetch(account))
      dispatch(balanceActions.doFetch(account, true))

      router.push(`/duel/${liveDuel.pairId}`)

      setState('idle')
    } catch (error) {
      // Ignore errors here because there might be a possibility
      // that both accepted at the same time
      setState('idle')
    } finally {
      dispatch(persistentToastActions.doRemoveToast('PairedCard'))
    }
  }

  const callContractToAbandonDuel = async () => {
    if (!account) {
      dispatch(authActions.doOpenModal())
      return
    }

    try {
      setState('loading')
      dispatch(
        persistentToastActions.doAddToast(
          'PairedCard',
          'Waiting for cancel confirmation...',
        ),
      )
      // @ts-ignore
      const web3Instance = new Web3(window.ethereum)

      const battleContract = new web3Instance.eth.Contract(
        BattleContractAbi,
        ConfigStore.get().BATTLE_CONTRACT_ADDRESS,
      )

      await battleContract.methods
        .abandon_game_post_pairing(liveDuel.pairId)
        .send({ from: account })

      dispatch(liveGamesActions.doFetch(account))
      dispatch(balanceActions.doFetch(account, true))

      setState('idle')
    } catch (error) {
      Web3Errors.handle(error)
      setState('idle')
    } finally {
      dispatch(persistentToastActions.doRemoveToast('PairedCard'))
    }
  }

  return (
    <div className={`${styles.card} ${styles.cardGreen}`}>
      <div className={`${styles.heading} ${styles.headingWaiting}`}>
        <div className={styles.player}>{liveDuel?.hero?.name || ''}</div>
        <div className={`${styles.player} ${styles.playerEmpty}`}>
          {isClosed ? 'Duel Expired' : 'Waiting...'}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.token}>
            <HeroCard hero={liveDuel.hero} isAnimated={true} size="medium" />
          </div>
          <div className={styles.bet}>
            <Image alt="Coin" src={'/gold_coin.png'} width={24} height={24} />
            <b>{formatGold(liveDuel.bet)} G</b>
          </div>
          {isClosed ? (
            <div className={styles.token}></div>
          ) : (
            <div
              className={`${styles.token} ${styles.timer}  ${styles.tokenSelect}`}
            >
              <CardTimer deadline={liveDuel.closesAt} />
            </div>
          )}
        </div>
        <div>
          {!isClosed && (
            <button
              style={{ marginRight: '10px' }}
              onClick={callContractToConfirmDuel}
              disabled={state === 'loading' || isBlockPeriod}
              className={`btn btn--large btn--green ${styles.awaitingButton}`}
            >
              {isBlockPeriod ? 'Loading...' : 'Begin Duel'}
            </button>
          )}
          {isClosed ? (
            <button
              onClick={callContractToAbandonDuel}
              disabled={state === 'loading'}
              className={`btn btn--large btn--dark ${styles.button}`}
            >
              Cancel
            </button>
          ) : null}
        </div>
      </div>
      <div className={styles.styling}></div>
    </div>
  )
}
