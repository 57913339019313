import { useWeb3React } from '@web3-react/core'
import moment from 'moment'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CreateSelectedCard } from '../components/cards/createSelectedCard'
import { JoinCard } from '../components/cards/joinCard'
import { PairedCard } from '../components/cards/pairedCard'
import { WaitingCard } from '../components/cards/waitingCard'
import { GamesWithHeading } from '../components/games'
import InfoBar from '../components/infoBarLobby'
import Pagination from '../components/pagination'
import Spinner from '../components/spinner'
import useGoldPriceInUsd from '../hooks/useGoldPriceInUsd'
import completedGamesActions from '../modules/completedGames/completedGamesActions'
import liveGamesActions from '../modules/liveGames/liveGamesActions'
import liveGamesSelectors from '../modules/liveGames/liveGamesSelectors'
import styles from '../styles/Lobby.module.scss'

export default function Lobby() {
  const dispatch = useDispatch()
  const liveGames = useSelector(liveGamesSelectors.selectPageData)
  const state = useSelector(liveGamesSelectors.selectState)
  const currentPage = useSelector(liveGamesSelectors.selectRenderedPage)
  const itemsPerPage = useSelector(liveGamesSelectors.selectItemsPerPage)
  const totalCount = useSelector(liveGamesSelectors.selectTotal)
  const { account } = useWeb3React()
  const router = useRouter()

  const { goldPriceInUsd, goldPriceInUsdState } = useGoldPriceInUsd()

  useEffect(() => {
    dispatch(liveGamesActions.doFetch(account))

    let pooling = setInterval(() => {
      dispatch(liveGamesActions.doRefreshData(account, router))
    }, 5 * 1000)

    return () => {
      if (pooling) {
        clearInterval(pooling)
      }
    }
  }, [account])

  useEffect(() => {
    dispatch(completedGamesActions.doFindAndWatchCurrentGame(account, router))

    let pooling = setInterval(() => {
      dispatch(completedGamesActions.doFindAndWatchCurrentGame(account, router))
    }, 5 * 1000)

    return () => {
      if (pooling) {
        clearInterval(pooling)
      }
    }
  }, [account])

  useEffect(() => {
    dispatch(completedGamesActions.doFindAndWarnOpenGame(account, router))
  }, [account])

  function onPageChange(page) {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    dispatch(liveGamesActions.doChangePage(page))
  }

  return (
    <div className={styles.lobby}>
      <InfoBar goldPriceInUsd={goldPriceInUsd} />
      {account || (state !== 'empty' && state !== 'error') ? (
        <div className={`heading ${styles.title}`}>Live games</div>
      ) : (
        ''
      )}

      {state === 'loading' && <Spinner />}

      {goldPriceInUsdState === 'idle' && (
        <div className={styles.games}>
          {state === 'idle' &&
            liveGames.map((liveGame) => {
              const isClosed = moment(liveGame.closesAt).isBefore(moment())

              if (liveGame.status === 'FIRSTPLAYERIN') {
                if (liveGame.isJoined) {
                  return (
                    <WaitingCard
                      key={liveGame.pairId + liveGame.status}
                      liveDuel={liveGame}
                    />
                  )
                }

                return (
                  <JoinCard
                    key={liveGame.pairId + liveGame.status}
                    liveDuel={liveGame}
                  />
                )
              }

              if (liveGame.status === 'BOTHCONFIRMED') {
                return (
                  <PairedCard
                    key={liveGame.pairId + liveGame.status}
                    liveDuel={liveGame}
                    isClosed={isClosed}
                  />
                )
              }

              return null
            })}

          {account && state !== 'loading' && (
            <CreateSelectedCard goldPriceInUsd={goldPriceInUsd} />
          )}
        </div>
      )}

      {state === 'idle' && (
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalCount={totalCount}
          onPageChange={onPageChange}
        />
      )}

      <GamesWithHeading />
    </div>
  )
}
